import "./contact.css";
import Linkdin from "../../assets/Link.png";
import Github from "../../assets/github.png";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    const name = form.current.your_name.value.trim();
    const email = form.current.your_email.value.trim();
    const message = form.current.message.value.trim();

    if (!name || !email || !message) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_b9zktef",
        "template_kcx22fl",
        form.current,
        "sxEcNo5DN8x2Uyt0_"
      )
      .then(
        (result) => {
          console.log(result.text);
          setErrorMessage("");
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          setErrorMessage("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <section id="contactPage">
      <div id="contact">
        <h2 className="contactTitle">Contact Here</h2>
        <span className="contactDesc">
          Please fill out your information to reach out here
        </span>
        <form className="contactForm" ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            className="name"
            placeholder="Your Name"
            name="your_name"
          />
          <input
            type="email"
            className="email"
            placeholder="Your Email"
            name="your_email"
          />
          <textarea
            className="message"
            name="message"
            rows="5"
            placeholder="Type Message"
          ></textarea>
          {errorMessage && <div className="error">{errorMessage}</div>}
          <button type="submit" value="Send" className="submitBtn">
            Submit
          </button>
          <div className="links">
            <a href="https://www.linkedin.com/in/anju-sharma-067720200/">
              <img src={Linkdin} alt="" className="link" />
            </a>
            <a href="https://github.com/SharmaAnju7">
              <img src={Github} alt="" className="link" />
            </a>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
