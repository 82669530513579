import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../assets/logo.png";
import Menu from "../../assets/menu.png";
import CV from "../../assets/CV.png";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState("intro");

  const handleLinkClick = (section) => {
    setActiveSection(section);
    scrollToSection(section);
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = CV;
    link.download = "CV";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 50 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.location.hash = activeSection;
  }, [activeSection]);

  return (
    <div className="navbar">
      <img src={logo} alt="Logo" className="logo" />
      <div className="desktopMenu">
        <a
          href="#intro"
          className={`desktopMenuListItem ${
            activeSection === "intro" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("intro")}
        >
          Home
        </a>
        <a
          href="#skills"
          className={`desktopMenuListItem ${
            activeSection === "skills" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("skills")}
        >
          About
        </a>
        <a
          href="#works"
          className={`desktopMenuListItem ${
            activeSection === "works" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("works")}
        >
          Projects
        </a>
        <a
          href="#contact"
          className={`desktopMenuListItem ${
            activeSection === "contact" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("contact")}
        >
          Contact
        </a>
      </div>

      <button className="desktopMenuBtn" onClick={handleDownload}>
        Download CV
      </button>

      <img
        src={Menu}
        alt="Menu"
        className="mobMenu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div className="navMenu" style={{ display: showMenu ? "flex" : "none" }}>
        <a
          href="#intro"
          className="listItem"
          onClick={() => {
            setShowMenu(false);
            handleLinkClick("intro");
          }}
        >
          Home
        </a>
        <a
          href="#skills"
          className="listItem"
          onClick={() => {
            setShowMenu(false);
            handleLinkClick("skills");
          }}
        >
          About
        </a>
        <a
          href="#works"
          className="listItem"
          onClick={() => {
            setShowMenu(false);
            handleLinkClick("works");
          }}
        >
          Projects
        </a>
        <a
          href="#contact"
          className="listItem"
          onClick={() => {
            setShowMenu(false);
            handleLinkClick("contact");
          }}
        >
          Contact
        </a>
      </div>
    </div>
  );
};

export default Navbar;
