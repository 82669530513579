
import './App.css';
import Navbar from './components/NavBar/navbar';
import Intro from './components/Intro/intro.js';
import Skill from './components/Skills/skill';
import Project from './components/Project/project';
import Contact from './components/Contact/contact';
import Footer from './components/Footer/footer';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Intro/>
      <Skill/>
      <Project />
      <Contact />
      <Footer />

    </div>
    
  );
}

export default App;
