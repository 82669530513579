import React from 'react'
import './footer.css';

function footer() {
  return (
   <footer className="footer">
    &copy; Copyright 2024, Anju Sharma. All right reserved.

   </footer>
  )
}

export default footer
