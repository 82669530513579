import React from "react";
import "./intro.css";
import bg from "../../assets/bg.jpg";

const intro = () => {
  return (
    <div className="hero">
      <section id="intro">
        <div className="introContent">
          <span className="hello">Hello,</span>
          <span className="introText">
            I'm <span className="introName">Anju</span>
            <br />
            Web Developer{" "}
          </span>
          <p className="introPara">
            I'm a passionate React developer with a knack for building <br />
            dynamic and responsive web applications.
          </p>
          <a href="/">
            <button className="btn">
              <img src="" alt="" />
              Hire Me
            </button>
          </a>
        </div>
      </section>

      <img src={bg} alt="" className="bg" />
    </div>
  );
};

export default intro;
