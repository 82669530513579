import React from "react";
import "./project.css";
import Project1 from "../../assets/Clone.png";
// import Project2 from "../../assets/food1.jpg";
import Project3 from "../../assets/flower1.png";
// import Project4 from "../../assets/flower2.jpg";
// import Project5 from "../../assets/flower3.jpg";
// import Project6 from "../../assets/flower4.jpg";

function project() {
  return (
    <section id="works">
      <h2 className="workstitle">My Projects</h2>
      <span className="worksDesc">
        {" "}
        A YouTube clone, and a React-based
        designed to grab attention and effectively showcase key features or offerings, all while ensuring responsiveness and seamless user experience..{" "}
      </span><br/><br/>
      <div className="worksImgs">
        <img src={Project1} alt="" className="worksImg" />
        {/* <img src={Project2} alt="" className="worksImg" /> */}
        <img src={Project3} alt="" className="worksImg" />
        {/* <img src={Project4} alt="" className="worksImg" /> */}
        {/* <img src={Project5} alt="" className="worksImg" /> */}
        {/* <img src={Project6} alt="" className="worksImg" /> */}
      </div>
      <button className="worksBtn">See More</button>
    </section>
  );
}

export default project;
