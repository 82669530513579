import React from "react";
import "./skill.css";
import UIDesign from "../../assets/ui.png";
import AppDesign from "../../assets/app.png";
import UIDesign1 from "../../assets/tail.png";
import JavaImg from "../../assets/Js.png";

function skill() {
  return (
    <section id="skills">
      <span className="skillTitle">What I do</span>
      <span className="description">
        I'm a passionate React developer with a knack for building <br />
        dynamic and responsive web applications.{" "}
      </span>
      <div className="skillBars">
        <div className="skillBar">
          <img src={UIDesign} alt="UIDesign" className="sillBarImg" />
          <div className="sillBarText">
            <h2>Html/Css</h2>
            <p>
              HTML structures web content, while CSS styles it. HTML provides
              the content's structure, and CSS defines its appearance.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img src={JavaImg} alt="UIDesign" className="sillBarImg" />
          <div className="sillBarText">
            <h2>JavaScript</h2>
            <p>
              JavaScript adds interactivity and functionality to web pages. It
              enables dynamic updates, form validation, and animations.
            </p>
          </div>
        </div>

        <div className="skillBar">
          <img src={AppDesign} alt="AppDesign" className="sillBarImg" />
          <div className="sillBarText">
            <h2>React Js</h2>
            <p>
              React.js simplifies building user interfaces by breaking them into
              reusable components.
            </p>
          </div>
        </div>

        <div className="skillBar">
          <img src={UIDesign1} alt="AppDesign" className="sillBarImg" />
          <div className="sillBarText">
            <h2>Tailwind Css</h2>
            <p>
              Tailwind CSS is a utility-first CSS framework that streamlines UI
              development.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default skill;
